<script>
  import linkMixin from '@/mixins/linkMixin'
  import screen from '@/helpers/screen'

  /**
   * A pill button with a label and optionally an icon.
   */
  export default {
    mixins: [linkMixin],
    inheritAttrs: false,
    props: {
      /** The button’s type. When left `undefined`, the type is determined automatically (`link` if there is a `to` or `href`, `button` otherwise). Use `submit` when a button’s purpose is to submit a form. Note that a `submit` button must be inside a `BaseForm` component, and its `click` event is automatically triggered when the user presses Enter while an input of the form is focused. */
      type: {
        type: String,
        default: undefined,
        validator: (value) => ['button', 'submit', 'link', 'display'].includes(value),
      },
      /** The height of the button. */
      height: {
        type: String,
        default: 'short',
        validator: (value) => ['short', 'tall'].includes(value),
      },
      /** The background color of the button. */
      color: {
        type: String,
        default: 'gray',
        validator: (value) => ['gray', 'primary', 'summer', 'black', 'spring'].includes(value),
      },
      /** Whether the button is filled with color (`true`) or just the outline (`false`). */
      outlineOnly: {
        type: Boolean,
        default: false,
      },
      /** The position of the icon relative to the label. */
      iconPosition: {
        type: String,
        default: 'left',
        validator: (value) => ['left', 'right'].includes(value),
      },
      /** The size of the icon, in Tailwind units. */
      iconSize: {
        type: Number,
        default: 4,
      },
      /** Whether the button is disabled. A disabled button is grayed out and cannot be focused or clicked. */
      disabled: {
        type: Boolean,
        default: false,
      },
      /** Whether the button's width is 100%. */
      fullWidth: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      screen,
      finalType() {
        if (this.type === undefined) {
          if (this.hasLink) {
            return 'link'
          } else {
            return 'button'
          }
        }
        return this.type
      },
      component() {
        switch (this.finalType) {
          case 'button':
          case 'submit':
            return 'button'
          case 'link':
            return 'BaseLink'
          case 'display':
            return 'div'
          default:
            return undefined
        }
      },
      propsToPassDown() {
        switch (this.finalType) {
          case 'button':
          case 'submit':
            return {
              type: this.finalType,
              disabled: this.disabled,
            }
          case 'link':
            if (this.disabled) {
              return {}
            }
            return this.linkProps
          default:
            return {}
        }
      },
    },
  }
</script>

<template>
  <div>
    <!-- for purgecss: button--primary button--gray button--summer button--black button--spring -->
    <component
      :is="component"
      :class="`flex justify-center items-center px-2 py-1 rounded-full ${
        fullWidth ? 'w-full' : ''
      } ${disabled ? 'button--disabled' : `button--${color}`} ${
        height === 'short' ? 'min-h-7' : 'min-h-10'
      } ${outlineOnly ? 'button--outline' : ''} ${
        type === 'display' ? 'button--display' : ''
      } text-xs text-center leading-tight transition duration-200`"
      v-bind="{ ...propsToPassDown, ...$attrs }"
      v-on="$listeners"
    >
      <BaseIcon
        v-if="$scopedSlots.icon && iconPosition === 'left'"
        :size="iconSize"
        class="mx-1 flex-shrink-0"
      >
        <!-- @slot Optional. An icon shown next to the button’s label. Should contain an SVG icon component. -->
        <slot name="icon" />
      </BaseIcon>
      <span class="-my-1 block py-1 overflow-hidden ellipsis">
        <!-- @slot The button’s label. Should contain text. -->
        <slot />
      </span>
      <BaseIcon
        v-if="$scopedSlots.icon && iconPosition === 'right'"
        :size="iconSize"
        class="ml-1 flex-shrink-0"
      >
        <slot name="icon" />
      </BaseIcon>
    </component>
  </div>
</template>

<style lang="postcss" scoped>
  .button {
    &--primary {
      &:not(.button--disabled) {
        &:not(.button--outline) {
          @apply border-none bg-primary text-white cursor-default;

          &:not(.button--display) {
            @apply cursor-pointer;

            &:hover {
              @apply bg-primary-bright text-white;
            }

            &:focus {
              @apply bg-primary-light text-white;
            }

            &:active {
              @apply bg-primary-dark text-white;
            }
          }
        }
        @apply border border-primary text-primary cursor-default;

        &:focus {
          @apply shadow-none;
        }

        &:not(.button--display) {
          @apply cursor-pointer;

          &:hover {
            @apply bg-gray-opacity4 text-primary;
          }

          &:focus {
            @apply bg-gray-opacity12 text-primary shadow-outline-primary-50;
          }

          &:active {
            @apply bg-gray-opacity20 text-primary;
          }
        }
      }
    }

    &--black {
      &:not(.button--disabled) {
        &:not(.button--outline) {
          @apply border-none bg-black text-white cursor-default;

          &:not(.button--display) {
            @apply cursor-pointer;

            &:hover {
              @apply bg-gray-light;
            }

            &:focus {
              @apply shadow-outline-gray-50;
            }

            &:active {
              @apply bg-gray-dark text-white;
            }
          }
        }
        @apply border border-black text-black cursor-default;

        &:focus {
          @apply shadow-none;
        }

        &:not(.button--display) {
          @apply cursor-pointer;

          &:hover {
            @apply bg-gray-opacity4 text-black;
          }

          &:focus {
            @apply bg-gray-opacity12 text-black shadow-outline-primary-50;
          }

          &:active {
            @apply bg-gray-opacity20 text-black;
          }
        }
      }
    }

    &--summer {
      &:not(.button--disabled) {
        &:not(.button--outline) {
          @apply border-none bg-summer text-white;

          &:not(.button--display) {
            @apply cursor-pointer;

            &:hover {
              @apply bg-summer-bright text-white;
            }

            &:focus {
              @apply bg-summer border-2 border-summer-darkest text-white;
            }

            &:active {
              @apply bg-summer-dark text-white;
            }
          }
        }
      }
    }

    &--spring {
      &:not(.button--disabled) {
        &:not(.button--outline) {
          @apply border-none bg-spring text-black;

          &:not(.button--display) {
            @apply cursor-pointer;
          }
        }
      }
    }

    &--gray {
      &:not(.button--disabled) {
        &:not(.button--outline) {
          @apply border-none bg-gray-lighter cursor-default;

          &:not(.button--display) {
            @apply cursor-pointer;

            &:hover {
              @apply bg-gray-light;
            }

            &:focus {
              @apply shadow-outline-gray-50;
            }

            &:active {
              @apply bg-gray-dark text-white;
            }
          }
        }
        @apply border border-gray-dark text-black cursor-default;

        &:focus {
          @apply shadow-none;
        }

        &:not(.button--display) {
          @apply cursor-pointer;

          &:hover {
            @apply bg-gray-opacity4 text-black;
          }

          &:focus {
            @apply bg-gray-opacity12 text-black shadow-outline-primary-50;
          }

          &:active {
            @apply bg-gray-opacity20 text-black;
          }
        }
      }
    }

    &--disabled {
      @apply bg-gray-lightest text-gray-light cursor-default;
    }
  }
</style>
